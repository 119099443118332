import { ApiReactiveService, type ApiServiceUseFetchOptions } from '@composable-api/service/api.reactive-service'
import { type ApiServiceFetchOptions } from '@composable-api/service/api.service'
import { NewsletterModel } from '../models/newsletter.model'
import type { ApiModel, ApiModelAttributes } from '@composable-api/api.model'

class NewslettersApiReactiveService<M extends ApiModel> extends ApiReactiveService<M> {
    useGet<T extends boolean>(options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'GET',
        })
    }

    post(data: Partial<ApiModelAttributes<M>>, options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'POST',
            body: data,
        })
    }
}

export default function useNewslettersApiService() {
    return new NewslettersApiReactiveService({
        endpoint: '/newsletters',
    }, NewsletterModel)
}
